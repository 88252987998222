import React, { lazy, Suspense } from "react";
import { render } from "react-dom";
import Spinner from "./app/components/Spinner";

import "antd/dist/antd.css";
import "./style.css";

const App = lazy(() => import("./App"));

render(
  <Suspense fallback={<Spinner />}>
    <App />
  </Suspense>,
  document.getElementById("root")
);
